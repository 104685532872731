//
//  Animation variables
//  _____________________________________________

$animation-bounce__translate                      : 30% !default;
$animation-bounce__duration                       : .75s !default;
$animation-bounce__iteration-count                : 3 !default;
$animation-bounce__timing-function                : ease-in-out !default;

@keyframes bounce-left {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(-$animation-bounce__translate); }
    100% { transform: translateX(0); }
}

@keyframes bounce-right {
    0%   { transform: translateX(0); }
    50%  { transform: translateX($animation-bounce__translate); }
    100% { transform: translateX(0); }
}